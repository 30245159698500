<template>
    <el-dialog
            title="选择导入模板"
            :visible.sync="dialogVisible"
            width="600px"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :before-close="handleClose">
        <div style="height: 450px;overflow: auto">
            <el-tree node-key="id" ref="tree" :data="treeData" class="treeDateCls"
                     :props="defaultProps" @node-click="selRecord">
                <div slot-scope="{ node, data }">
                    <el-tooltip class="item" effect="dark" :content="node.label" placement="top-start">
                        <div class="omit">{{ node.label }}</div>
                    </el-tooltip>
                </div>
            </el-tree>
        </div>
        <div class="text_center">
            <el-button size="small" type="primary" @click="downLoad()" :disabled="!seData.length">下载</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: "temDownload",
    data() {
        return {
            dialogVisible: false,
            treeData: [],
            defaultProps: {
                children: 'children',
                label: 'archivesBasicName'
            },
            seData: [],
        }
    },

    methods: {
        init(data) {
            this.treeData = data
            this.dialogVisible = true
        },

        selRecord(selectData) {
            this.seData = [selectData]
        },

        handleClose() {
            this.seData = []
            this.dialogVisible = false
        },

        downLoad() {
            this.$axios(this.api.collection.exportModel, {
                archivesBasicDataId: this.seData[0].id
            }, 'get', 'blob').then(res => {
                const blob = new Blob([res.data], {
                    // 下载的文件格式自己在这边更改        type的值就好了
                    type: 'application/vnd.ms-excel'
                })
                let filename = this.seData[0].archivesBasicName;
                let link = document.createElement('a')
                link.download = decodeURI(filename)
                link.href = window.URL.createObjectURL(blob)
                document.body.appendChild(link);
                link.click()
                URL.revokeObjectURL(link.href);  //释放url
                document.body.removeChild(link);  //释放标签
            })
        },
    }
}
</script>

<style scoped>

</style>